select::-ms-expand {
    display: none;
}
select:focus::-ms-value  {
    background: #FFF;
    color: #494949;
}
@-ms-viewport {
    width: device-width;
}

@keyframes rotating {
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
.loader-icon {
	-webkit-animation: rotating 1s linear infinite;
	-moz-animation: rotating 1s linear infinite;
	-ms-animation: rotating 1s linear infinite;
	-o-animation: rotating 1s linear infinite;
	animation: rotating 1s linear infinite;
	-webkit-animation-timing-function: linear; /* Chrome, Safari, Opera */
	animation-timing-function: linear;
}

